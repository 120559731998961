import React, { useContext, useState } from "react";
import { Link, useLoaderData, useNavigate } from "react-router-dom";
import { ItineraryContext } from "./context/provider";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DeleteIcon,
  PencilIcon,
  PlaneIcon,
  PlusIcon,
  Trash2Icon,
} from "lucide-react";
import TaskIcon from "./TaskIcon";
import AddTaskModal from "./AddTaskModal";
import EditTaskModal from "./EditTaskModal";
import { formatDate } from "./utils";
import EditItineraryModal from "./EditItineraryModal";

const ItineraryItem = () => {
  const id = useLoaderData();
  const navigate = useNavigate();
  const { getItineraryById, deleteItinerary } = useContext(ItineraryContext);
  const itinerary = getItineraryById(id);

  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
  const [isTaskEditModalOpen, setIsTaskEditModalOpen] = useState(false);
  const [taskId, setTaskId] = useState(null);

  const [isItineraryEditModalOpen, setIsItineraryEditModalOpen] =
    useState(false);

  if (!itinerary) {
    return <div>Itinerary not found</div>;
  }

  return (
    <>
      <header className="bg-white">
        <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
          <Link
            to="/"
            className="flex flex-row items-center text-indigo-600 hover:text-indigo-500 sm:-ml-4"
          >
            <ChevronLeftIcon className="inline-block h-5 w-5" />
            Back to Itineraries
          </Link>
          <div className="mt-8 flex flex-row justify-between">
            <h1 className="text-3xl font-bold capitalize tracking-tight text-gray-900">
              {itinerary.name}{" "}
              <span className="text-base font-normal text-gray-700">
                ({formatDate(itinerary.startDate)} -{" "}
                {formatDate(itinerary.endDate)})
              </span>
            </h1>
            <div className="space-x-2">
              <button
                type="button"
                onClick={() => setIsItineraryEditModalOpen(true)}
                className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                <PencilIcon
                  className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-900"
                  aria-hidden="true"
                />
                Edit Itinerary
              </button>
              <button
                type="button"
                onClick={() => {
                  deleteItinerary(itinerary.id);
                  navigate("/");
                }}
                className="inline-flex items-center rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-red-300 hover:bg-red-600"
              >
                <Trash2Icon
                  className="-ml-0.5 mr-1.5 h-5 w-5 text-white"
                  aria-hidden="true"
                />
                Delete Itinerary
              </button>
            </div>
          </div>
        </div>
      </header>
      <main>
        <div className="mx-auto max-w-7xl py-3 sm:px-6 lg:px-8">
          <div className="flex flex-row justify-between border-b px-8 pb-5">
            <h2 className="text-xl font-semibold text-gray-900">Tasks</h2>
            <button
              onClick={() => setIsTaskModalOpen(true)}
              className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
              Add Task
            </button>
          </div>
          {!itinerary.tasks.length && (
            <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
              <div className="mx-auto h-full max-w-4xl px-10 py-12">
                <div className="flex h-full flex-col items-center justify-center gap-4 py-12">
                  <TaskIcon className="h-28 w-auto text-gray-400" />
                  <p className="mb-4 text-lg font-semibold text-gray-600">
                    You have not added any tasks.
                  </p>
                  <button
                    onClick={() => setIsTaskModalOpen(true)}
                    className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    <PlusIcon
                      className="-ml-0.5 mr-1.5 h-5 w-5"
                      aria-hidden="true"
                    />
                    Add Task
                  </button>
                </div>
              </div>
            </div>
          )}
          <ul role="list" className="mt-4 divide-y divide-gray-100">
            {itinerary.tasks.map((task) => (
              <li key={task.id}>
                <button
                  onClick={() => {
                    setTaskId(task.id);
                    setIsTaskEditModalOpen(true);
                  }}
                  className="flex w-full items-center justify-between gap-x-6 px-8 py-5 hover:bg-gray-50"
                >
                  <div className="flex min-w-0 gap-x-4">
                    <div className="min-w-0 flex-auto">
                      <p className="text-sm leading-6 text-gray-900">
                        {task.name}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-row items-center gap-4">
                    <ChevronRightIcon className="size-5" />
                  </div>
                </button>
              </li>
            ))}
          </ul>
        </div>
      </main>

      <AddTaskModal
        id={id}
        isOpen={isTaskModalOpen}
        setIsOpen={setIsTaskModalOpen}
      />

      <EditItineraryModal
        id={id}
        isOpen={isItineraryEditModalOpen}
        setIsOpen={setIsItineraryEditModalOpen}
      />

      {taskId && (
        <EditTaskModal
          id={id}
          taskId={taskId}
          isOpen={isTaskEditModalOpen}
          setIsOpen={setIsTaskEditModalOpen}
          onClose={() => setTaskId(null)}
        />
      )}
    </>
  );
};

export default ItineraryItem;
