import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ItineraryList from "./ItineraryList";
import { ItineraryProvider } from "./context/provider";
import ItineraryItem from "./ItineraryItem";

const router = createBrowserRouter([
  {
    path: "/",
    element: <ItineraryList />,
  },
  {
    path: "/:id",
    loader: ({ params }) => {
      return params.id;
    },
    element: <ItineraryItem />,
  },
]);

const App = () => {
  return (
    <ItineraryProvider>
      <RouterProvider router={router} />
    </ItineraryProvider>
  );
};

export default App;
