import React, { createContext, useReducer } from "react";
import ItineraryReducer from "./reducer";

// Initial state
const initialState = {
  itinerary: [],
};

// Create context
export const ItineraryContext = createContext(initialState);

// Provider component
export const ItineraryProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ItineraryReducer, initialState);

  // Actions
  function deleteItinerary(id) {
    dispatch({
      type: "DELETE_ITINERARY",
      payload: id,
    });
  }

  function deleteItineraryTask(itineraryId, taskId) {
    dispatch({
      type: "DELETE_ITINERARY_TASK",
      payload: { itineraryId, taskId },
    });
  }

  function addItinerary(itinerary) {
    dispatch({
      type: "ADD_ITINERARY",
      payload: itinerary,
    });
  }

  function addItineraryTask(id, task) {
    dispatch({
      type: "ADD_ITINERARY_TASK",
      payload: { id, task },
    });
  }

  function editItinerary(id, itinerary) {
    dispatch({
      type: "EDIT_ITINERARY",
      payload: { id, itinerary },
    });
  }

  function editItineraryTask(itineraryId, taskId, task) {
    dispatch({
      type: "EDIT_ITINERARY_TASK",
      payload: { itineraryId, taskId, task },
    });
  }

  function getItineraryById(id) {
    return state.itinerary.find((it) => it.id === id);
  }

  function getTaskById(itineraryId, taskId) {
    const itinerary = getItineraryById(itineraryId);
    return itinerary.tasks.find((t) => t.id === taskId);
  }

  return (
    <ItineraryContext.Provider
      value={{
        itinerary: state.itinerary,
        deleteItinerary,
        deleteItineraryTask,
        addItinerary,
        editItinerary,
        getItineraryById,
        addItineraryTask,
        editItineraryTask,
        getTaskById,
      }}
    >
      {children}
    </ItineraryContext.Provider>
  );
};
