import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import React, { useContext, useState } from "react";
import { ItineraryContext } from "./context/provider";

const NewItineraryModal = ({ isOpen, setIsOpen }) => {
  const { addItinerary } = useContext(ItineraryContext);
  const [itineraryName, setItineraryName] = useState("");
  const [itineraryStartDate, setItineraryStartDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [itineraryEndDate, setItineraryEndDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  const handleAdd = () => {
    if (!itineraryName || !itineraryStartDate || !itineraryEndDate) {
      return;
    }

    // Add itinerary to the list
    addItinerary({
      id: Math.random().toString(36).substr(2, 9),
      name: itineraryName,
      startDate: itineraryStartDate,
      endDate: itineraryEndDate,
      tasks: [],
    });
    setItineraryName("");
    setItineraryStartDate(new Date().toISOString().split("T")[0]);
    setItineraryEndDate(new Date().toISOString().split("T")[0]);
    setIsOpen(false);
  };

  return (
    <Transition show={isOpen}>
      <Dialog className="relative z-10" onClose={setIsOpen}>
        <TransitionChild
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start w-full">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                      <DialogTitle
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Add New Itinerary
                      </DialogTitle>
                      <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2">
                        <div className="col-span-1 sm:col-span-2">
                          <label
                            htmlFor="itinerary-name"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Name your itinerary
                          </label>
                          <div className="mt-2">
                            <input
                              type="text"
                              id="itinerary-name"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              value={itineraryName}
                              onChange={(e) => setItineraryName(e.target.value)}
                              placeholder="Name your itinerary"
                            />
                          </div>
                        </div>
                        <div className="col-span-1">
                          <label
                            htmlFor="itinerary-start-date"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Start Date
                          </label>
                          <div className="mt-2">
                            <input
                              type="date"
                              id="itinerary-start-date"
                              min={new Date().toISOString().split("T")[0]}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              value={itineraryStartDate}
                              onChange={(e) => {
                                setItineraryStartDate(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-span-1">
                          <label
                            htmlFor="
                            itinerary-end-date"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            End Date
                          </label>
                          <div className="mt-2">
                            <input
                              type="date"
                              id="itinerary-end-date"
                              min={itineraryStartDate}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              value={itineraryEndDate}
                              onChange={(e) =>
                                setItineraryEndDate(e.target.value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                    onClick={handleAdd}
                  >
                    Add Itinerary
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setIsOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default NewItineraryModal;
