import { ChevronRightIcon, PlusIcon, SearchIcon } from "lucide-react";
import PlaneIcon from "./PlaneIcon";
import { Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { ItineraryContext } from "./context/provider";
import NewItineraryModal from "./NewItineraryModal";
import { formatDate } from "./utils";

function ItineraryList() {
  const { itinerary } = useContext(ItineraryContext);
  const [filteredItinerary, setFilteredItinerary] = useState(itinerary);
  const [query, setQuery] = useState("");

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!query) {
      setFilteredItinerary(itinerary);
      return;
    }

    const filtered = itinerary.filter((it) =>
      it.name.toLowerCase().includes(query.toLowerCase()),
    );

    setFilteredItinerary(filtered);
  }, [query, itinerary]);

  return (
    <>
      <header className="bg-white">
        <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold tracking-tight text-gray-900">
            Itinerary Planner
          </h1>
        </div>
      </header>
      <main>
        <div className="mx-auto w-full max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
          <div className="flex flex-row justify-between">
            <div className="relative mt-2 w-full max-w-sm rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <SearchIcon className="h-4 w-4 text-gray-500" />
              </div>
              <input
                type="text"
                className="block w-full rounded-md border-0 py-1.5 pl-10 pr-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Search"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>

            <span className="sm:ml-3">
              <button
                onClick={() => setIsOpen(true)}
                className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                <PlusIcon
                  className="-ml-0.5 mr-1.5 h-5 w-5"
                  aria-hidden="true"
                />
                New Itinerary
              </button>
            </span>
          </div>
        </div>

        {!itinerary.length && (
          <div className="mx-auto h-full w-full max-w-4xl px-10 py-12">
            <div className="flex h-full flex-col items-center justify-center gap-4 py-12">
              <PlaneIcon className="h-28 w-auto text-gray-400" />
              <p className="mb-4 text-lg font-semibold text-gray-600">
                You have not created any itineraries.
              </p>
              <button
                onClick={() => setIsOpen(true)}
                className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                <PlusIcon
                  className="-ml-0.5 mr-1.5 h-5 w-5"
                  aria-hidden="true"
                />
                New Itinerary
              </button>
            </div>
          </div>
        )}

        {!filteredItinerary.length && itinerary.length > 0 && (
          <div className="mx-auto h-full w-full max-w-4xl px-10 py-12">
            <div className="flex h-full flex-col items-center justify-center gap-4 py-12">
              <PlaneIcon className="h-28 w-auto text-gray-400" />
              <h2 className="mb-4 text-lg font-semibold text-gray-600">
                No itineraries found.
              </h2>
            </div>
          </div>
        )}

        <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
          <ul role="list" className="divide-y divide-gray-100">
            {filteredItinerary.map((it) => (
              <li key={it.id}>
                <Link
                  to={it.id}
                  className="flex items-center justify-between gap-x-6 px-8 py-5 hover:bg-gray-100"
                >
                  <div className="flex min-w-0 gap-x-4">
                    <div className="min-w-0 flex-auto">
                      <p className="text-sm font-semibold leading-6 text-gray-900">
                        {it.name}
                      </p>
                      <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                        {formatDate(it.startDate)} - {formatDate(it.endDate)}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-row items-center gap-4">
                    <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                      <p className="text-sm leading-6 text-gray-900">
                        {it.tasks.length === 1
                          ? "1 task"
                          : `${it.tasks.length} tasks`}
                      </p>
                    </div>
                    <ChevronRightIcon className="size-5" />
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </main>

      <NewItineraryModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
}

export default ItineraryList;
