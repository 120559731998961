export default (state, action) => {
  switch (action.type) {
    case "DELETE_ITINERARY":
      return {
        ...state,
        itinerary: state.itinerary.filter((it) => it.id !== action.payload),
      };
    case "DELETE_ITINERARY_TASK":
      return {
        ...state,
        itinerary: state.itinerary.map((it) =>
          it.id === action.payload.itineraryId
            ? {
                ...it,
                tasks: it.tasks.filter((t) => t.id !== action.payload.taskId),
              }
            : it,
        ),
      };
    case "ADD_ITINERARY":
      return {
        ...state,
        itinerary: [action.payload, ...state.itinerary],
      };
    case "ADD_ITINERARY_TASK":
      return {
        ...state,
        itinerary: state.itinerary.map((it) =>
          it.id === action.payload.id
            ? {
                ...it,
                tasks: [action.payload.task, ...it.tasks],
              }
            : it,
        ),
      };
    case "EDIT_ITINERARY":
      return {
        ...state,
        itinerary: state.itinerary.map((it) =>
          it.id === action.payload.id ? action.payload.itinerary : it,
        ),
      };
    case "EDIT_ITINERARY_TASK":
      return {
        ...state,
        itinerary: state.itinerary.map((it) =>
          it.id === action.payload.itineraryId
            ? {
                ...it,
                tasks: it.tasks.map((t) =>
                  t.id === action.payload.taskId ? action.payload.task : t,
                ),
              }
            : it,
        ),
      };
    default:
      return state;
  }
};
