import React from "react";

const TaskIcon = (props) => {
  return (
    <svg data-name="Layer 1" viewBox="0 0 704.36225 635" {...props}>
      <path
        d="M685.45128,501.5h-258a33.03734,33.03734,0,0,1-33-33v-303a33.03734,33.03734,0,0,1,33-33H667.47813l50.97315,40.62891V468.5A33.03734,33.03734,0,0,1,685.45128,501.5Z"
        transform="translate(-247.81887 -132.5)"
        fill="#e6e6e6"
      />
      <path
        d="M427.45117,142a23.52653,23.52653,0,0,0-23.5,23.5v303a23.52653,23.52653,0,0,0,23.5,23.5h258a23.52653,23.52653,0,0,0,23.5-23.5V177.70557L664.155,142Z"
        transform="translate(-247.81887 -132.5)"
        fill="#fff"
      />
      <path
        d="M635.27028,230.10427H517.80648a5.49538,5.49538,0,1,1,0-10.99076h117.4638a5.49538,5.49538,0,1,1,0,10.99076Z"
        transform="translate(-247.81887 -132.5)"
        fill="#ccc"
      />
      <path
        d="M657.93873,248.65118H517.80648a5.49538,5.49538,0,1,1,0-10.99076H657.93873a5.49538,5.49538,0,1,1,0,10.99076Z"
        transform="translate(-247.81887 -132.5)"
        fill="#ccc"
      />
      <path
        d="M635.27028,313.22192H517.80648a5.49538,5.49538,0,1,1,0-10.99076h117.4638a5.49538,5.49538,0,1,1,0,10.99076Z"
        transform="translate(-247.81887 -132.5)"
        fill="#ccc"
      />
      <path
        d="M657.93873,331.76884H517.80648a5.49538,5.49538,0,1,1,0-10.99076H657.93873a5.49538,5.49538,0,1,1,0,10.99076Z"
        transform="translate(-247.81887 -132.5)"
        fill="#ccc"
      />
      <path
        d="M635.27028,396.33958H517.80648a5.49538,5.49538,0,1,1,0-10.99076h117.4638a5.49538,5.49538,0,1,1,0,10.99076Z"
        transform="translate(-247.81887 -132.5)"
        fill="#ccc"
      />
      <path
        d="M657.93873,414.88649H517.80648a5.49538,5.49538,0,0,1,0-10.99076H657.93873a5.49538,5.49538,0,1,1,0,10.99076Z"
        transform="translate(-247.81887 -132.5)"
        fill="#ccc"
      />
      <circle
        id="b6fa6563-5e20-492f-ac6f-1d924df782c1"
        data-name="Ellipse 44"
        cx="221.07272"
        cy="101.38234"
        r="19.42315"
        fill="#4f46e5"
      />
      <path
        id="bcd12bac-9f30-4deb-ae50-48c1cb737c9b-301"
        data-name="Path 395"
        d="M466.96686,241.2917a2.01446,2.01446,0,0,1-1.21191-.40277l-.02168-.01626-4.5647-3.49185a2.02852,2.02852,0,0,1,2.46838-3.21973l2.95665,2.2673,6.98671-9.11494a2.02766,2.02766,0,0,1,2.84288-.37551l.00059.00044-.04336.06021.04453-.06021a2.03011,2.03011,0,0,1,.37507,2.84345l-8.2179,10.71638a2.02887,2.02887,0,0,1-1.61347.79108Z"
        transform="translate(-247.81887 -132.5)"
        fill="#fff"
      />
      <circle
        id="b95850ed-3210-4af9-b38f-3ce1288a2453"
        data-name="Ellipse 44"
        cx="223.07272"
        cy="184.5"
        r="19.42315"
        fill="#ccc"
      />
      <circle
        id="b1885188-7f5c-4441-a082-07b02d322a90"
        data-name="Ellipse 44"
        cx="221.07272"
        cy="267.61766"
        r="19.42315"
        fill="#ccc"
      />
      <path
        d="M717.45905,174.43645h-39.929a10.99076,10.99076,0,0,1-10.99076-10.99076v-29.491a.68692.68692,0,0,1,1.11347-.53844l50.23282,39.79482A.68692.68692,0,0,1,717.45905,174.43645Z"
        transform="translate(-247.81887 -132.5)"
        fill="#e6e6e6"
      />
      <path
        d="M447.16675,558.2722,294.52087,590.44728A17.01917,17.01917,0,0,1,274.38013,577.319l-26.19381-124.2694a17.01916,17.01916,0,0,1,13.12822-20.14075l152.64588-32.17507a17.01916,17.01916,0,0,1,20.14075,13.12823L460.295,538.13146A17.01915,17.01915,0,0,1,447.16675,558.2722Z"
        transform="translate(-247.81887 -132.5)"
        fill="#fff"
      />
      <path
        d="M447.16675,558.2722,294.52087,590.44728A17.01917,17.01917,0,0,1,274.38013,577.319l-26.19381-124.2694a17.01916,17.01916,0,0,1,13.12822-20.14075l152.64588-32.17507a17.01916,17.01916,0,0,1,20.14075,13.12823L460.295,538.13146A17.01915,17.01915,0,0,1,447.16675,558.2722ZM261.72705,434.8659a15.017,15.017,0,0,0-11.58374,17.77124l26.19381,124.26941a15.017,15.017,0,0,0,17.77125,11.58373L446.75425,556.3152A15.017,15.017,0,0,0,458.338,538.544l-26.19381-124.2694a15.017,15.017,0,0,0-17.77124-11.58374Z"
        transform="translate(-247.81887 -132.5)"
        fill="#3f3d56"
      />
      <path
        id="b3d30341-bfe2-418c-85c9-1bd82b5f5e0c-302"
        data-name="Path 411"
        d="M404.47614,441.709l-77.03838,16.23834a2.73088,2.73088,0,0,1-3.22931-1.86307,2.63064,2.63064,0,0,1,1.99859-3.25411l78.42034-16.52963c3.06195,1.72557,2.09418,4.93517-.15219,5.40867Z"
        transform="translate(-247.81887 -132.5)"
        fill="#ccc"
      />
      <path
        id="ba7e3b3b-1e3b-4053-b678-ceb2b593f6ee-303"
        data-name="Path 412"
        d="M407.32446,455.222l-77.03838,16.23835a2.7309,2.7309,0,0,1-3.22932-1.86307,2.63065,2.63065,0,0,1,1.99859-3.25411l78.42034-16.52964c3.062,1.72558,2.09419,4.93518-.15219,5.40868Z"
        transform="translate(-247.81887 -132.5)"
        fill="#ccc"
      />
      <path
        id="f6076b75-2d9c-4839-8b09-e00257d358cf-304"
        data-name="Path 413"
        d="M315.25392,482.66466l-23.51137,4.95578a2.962,2.962,0,0,1-3.50569-2.28509L282.38863,457.59a2.962,2.962,0,0,1,2.2851-3.50569l23.51136-4.95578a2.963,2.963,0,0,1,3.5057,2.28509L317.539,479.159a2.962,2.962,0,0,1-2.28509,3.50569Z"
        transform="translate(-247.81887 -132.5)"
        fill="#ccc"
      />
      <path
        id="acc57b87-b2e4-43d3-aa96-a8e2462d330c-305"
        data-name="Path 414"
        d="M412.93232,482.93731,295.35763,507.72a2.73087,2.73087,0,0,1-3.22929-1.86307,2.6306,2.6306,0,0,1,1.99858-3.25411l118.95759-25.07418c3.06195,1.72557,2.09418,4.93517-.15219,5.40867Z"
        transform="translate(-247.81887 -132.5)"
        fill="#ccc"
      />
      <path
        id="e5415755-f7b6-4857-8e09-1413b7f93e97-306"
        data-name="Path 415"
        d="M415.78146,496.45432,298.20678,521.237a2.73086,2.73086,0,0,1-3.22929-1.86306,2.63061,2.63061,0,0,1,1.99858-3.25412l118.95759-25.07418c3.062,1.72558,2.09418,4.93518-.1522,5.40868Z"
        transform="translate(-247.81887 -132.5)"
        fill="#ccc"
      />
      <path
        id="afce3f4d-2700-4434-8207-f3b7a686e1c3-307"
        data-name="Path 416"
        d="M418.62875,509.96248,301.05406,534.74517a2.73087,2.73087,0,0,1-3.22929-1.86307,2.63062,2.63062,0,0,1,1.99858-3.25411l118.95759-25.07418c3.062,1.72558,2.09419,4.93518-.15219,5.40867Z"
        transform="translate(-247.81887 -132.5)"
        fill="#ccc"
      />
      <path
        id="abb1f551-863d-408b-9aec-d87e952e72e4-308"
        data-name="Path 417"
        d="M421.47768,523.47848,303.903,548.26117a2.73087,2.73087,0,0,1-3.22929-1.86307,2.6306,2.6306,0,0,1,1.99858-3.25411l118.95759-25.07418c3.06195,1.72558,2.09418,4.93517-.1522,5.40867Z"
        transform="translate(-247.81887 -132.5)"
        fill="#ccc"
      />
      <path
        id="bbed1b24-9150-4534-8e9c-e5347be55157-309"
        data-name="Path 418"
        d="M424.32518,536.98766,306.75049,561.77035a2.73088,2.73088,0,0,1-3.22929-1.86307,2.63062,2.63062,0,0,1,1.99859-3.25411L424.47738,531.579c3.062,1.72558,2.09418,4.93518-.1522,5.40867Z"
        transform="translate(-247.81887 -132.5)"
        fill="#ccc"
      />
      <path
        d="M445.07972,475.12077a10.52517,10.52517,0,0,0-.68327,1.51011l-48.13337,11.94434-8.37345-8.66255-14.725,10.9283,13.33322,16.77594a8,8,0,0,0,9.289,2.428l52.22932-21.34223a10.4971,10.4971,0,1,0-2.9364-13.58189Z"
        transform="translate(-247.81887 -132.5)"
        fill="#a0616a"
      />
      <path
        d="M280.78308,581.4131a10.7427,10.7427,0,0,0,6.37825-15.18768l33.12421-92.06728-23.00365-4.07668-25.32664,91.781a10.80091,10.80091,0,0,0,8.82783,19.55062Z"
        transform="translate(-247.81887 -132.5)"
        fill="#a0616a"
      />
      <path
        d="M316.07872,490.50109l-22.71253-8.71684a4.81689,4.81689,0,0,1-2.46974-6.863l11.915-21.1257a13.37736,13.37736,0,0,1,24.95,9.65954l-5.25552,23.59756a4.81688,4.81688,0,0,1-6.42721,3.44849Z"
        transform="translate(-247.81887 -132.5)"
        fill="#4f46e5"
      />
      <polygon
        points="119.525 621.766 131.785 621.765 137.617 574.477 119.523 574.478 119.525 621.766"
        fill="#a0616a"
      />
      <path
        d="M364.2164,750.26334l24.1438-.001h.001a15.38605,15.38605,0,0,1,15.38647,15.38623v.5l-39.53052.00147Z"
        transform="translate(-247.81887 -132.5)"
        fill="#2f2e41"
      />
      <polygon
        points="50.525 621.766 62.785 621.765 68.617 574.477 50.523 574.478 50.525 621.766"
        fill="#a0616a"
      />
      <path
        d="M295.2164,750.26334l24.1438-.001h.001a15.38605,15.38605,0,0,1,15.38647,15.38623v.5l-39.53052.00147Z"
        transform="translate(-247.81887 -132.5)"
        fill="#2f2e41"
      />
      <path
        d="M311.19617,735.233H295.68152a4.50041,4.50041,0,0,1-4.49487-4.71387l7.36938-158.332.46436-.0127,74.91919-1.95312L388.6,728.64416a4.50022,4.50022,0,0,1-4.12085,4.91113l-17.42627,1.39356a4.50224,4.50224,0,0,1-4.76709-3.582L339.99548,618.59045a1.45374,1.45374,0,0,0-1.469-1.19922h-.0061a1.45449,1.45449,0,0,0-1.46631,1.21094l-21.44263,113A4.50952,4.50952,0,0,1,311.19617,735.233Z"
        transform="translate(-247.81887 -132.5)"
        fill="#2f2e41"
      />
      <circle cx="91.79701" cy="273.91413" r="24.56103" fill="#a0616a" />
      <path
        d="M331.38025,590.74182c-11.7063,0-23.73023-3.07715-33.698-12.17481a4.55183,4.55183,0,0,1-1.46948-3.84961c1.06689-9.58593,6.24219-59.081,3.32764-92.14355A40.01958,40.01958,0,0,1,312.496,449.26916a39.59816,39.59816,0,0,1,33.96216-9.834h.00024c.30591.05468.61158.1123.91773.1709,19.66357,3.80566,33.32593,22.0039,31.78,42.33105-2.25586,29.66016-4.27808,69.7793-1.10279,92.46777a4.49261,4.49261,0,0,1-2.41479,4.63672C368.4137,582.692,350.31238,590.74182,331.38025,590.74182Z"
        transform="translate(-247.81887 -132.5)"
        fill="#4f46e5"
      />
      <path
        d="M363.42949,492.26837A4.81166,4.81166,0,0,1,361.32,488.857l-2.9923-23.98982a13.37737,13.37737,0,0,1,25.75414-7.24809l9.85628,22.16112a4.81687,4.81687,0,0,1-3.10992,6.59769l-23.43727,6.522A4.81143,4.81143,0,0,1,363.42949,492.26837Z"
        transform="translate(-247.81887 -132.5)"
        fill="#4f46e5"
      />
      <path
        d="M331.04177,430.62836a17.59785,17.59785,0,0,1-6.7972-1.2298c-.95139-.36664-1.939-.668-2.88863-1.03489-8.38662-3.24016-13.9112-12.17188-14.10907-21.16048s4.457-17.71287,11.36912-23.46238,15.865-8.70134,24.82987-9.38394c9.65661-.73527,20.53026,1.7136,25.92626,9.7556,1.43633,2.14065,2.44084,4.73784,1.58976,7.32247a4.68558,4.68558,0,0,1-1.32028,2.028c-2.40978,2.144-4.81222.53191-7.26879.38949-3.37653-.19577-6.40925,2.53733-7.49926,5.73907s-.61019,6.7266.26992,9.99228a24.96206,24.96206,0,0,1,1.25825,6.07589,6.10832,6.10832,0,0,1-2.53094,5.37854c-2.10992,1.27537-4.88078.53724-6.99194-.73607s-3.93534-3.04373-6.21577-3.98072-5.34419-.72915-6.6715,1.34848a7.379,7.379,0,0,0-.84344,2.43522c-1.18992,5.42125-.91643,5.102-2.10635,10.52322Z"
        transform="translate(-247.81887 -132.5)"
        fill="#2f2e41"
      />
      <path
        d="M439.48671,767.5h-190a1,1,0,0,1,0-2h190a1,1,0,1,1,0,2Z"
        transform="translate(-247.81887 -132.5)"
        fill="#ccc"
      />
      <path
        d="M925.831,561.12942,789.77349,637.4468a17.01916,17.01916,0,0,1-23.14342-6.51015l-62.13018-110.7648A17.01917,17.01917,0,0,1,711.01,497.02843l136.05755-76.31738a17.01916,17.01916,0,0,1,23.14342,6.51014L932.34119,537.986A17.01918,17.01918,0,0,1,925.831,561.12942Z"
        transform="translate(-247.81887 -132.5)"
        fill="#fff"
      />
      <path
        d="M925.831,561.12942,789.77349,637.4468a17.01916,17.01916,0,0,1-23.14342-6.51015l-62.13018-110.7648A17.01917,17.01917,0,0,1,711.01,497.02843l136.05755-76.31738a17.01916,17.01916,0,0,1,23.14342,6.51014L932.34119,537.986A17.01918,17.01918,0,0,1,925.831,561.12942ZM711.98847,498.77276a15.017,15.017,0,0,0-5.74425,20.42066l62.13017,110.7648a15.017,15.017,0,0,0,20.42067,5.74425l136.05755-76.31738a15.017,15.017,0,0,0,5.74425-20.42067l-62.13018-110.7648a15.017,15.017,0,0,0-20.42066-5.74425Z"
        transform="translate(-247.81887 -132.5)"
        fill="#3f3d56"
      />
      <path
        id="b4188fdd-8694-46fd-b6a9-2365408da1f7-310"
        data-name="Path 411"
        d="M850.26046,462.64813,781.594,501.16452a2.73088,2.73088,0,0,1-3.63848-.813,2.63064,2.63064,0,0,1,.93491-3.70264l69.89825-39.20732c3.43768.7318,3.47319,4.08394,1.47093,5.207Z"
        transform="translate(-247.81887 -132.5)"
        fill="#ccc"
      />
      <path
        id="e79bc6ad-6d6d-496e-bd80-f918a84d8140-311"
        data-name="Path 412"
        d="M857.01649,474.6927,788.35,513.20909a2.73088,2.73088,0,0,1-3.63848-.813,2.63063,2.63063,0,0,1,.93491-3.70263l69.89825-39.20732c3.43768.73179,3.47319,4.08393,1.47093,5.207Z"
        transform="translate(-247.81887 -132.5)"
        fill="#ccc"
      />
      <path
        id="b0898467-4323-4072-9ee9-29d386bbe07d-312"
        data-name="Path 413"
        d="M777.35262,528.39326l-20.95633,11.75483a2.962,2.962,0,0,1-4.02834-1.13316L738.4963,514.28475a2.962,2.962,0,0,1,1.13315-4.02834l20.95634-11.75483a2.963,2.963,0,0,1,4.02834,1.13316L778.48579,524.365a2.962,2.962,0,0,1-1.13315,4.02834Z"
        transform="translate(-247.81887 -132.5)"
        fill="#ccc"
      />
      <path
        id="aea6c33f-bf9b-47d5-9d2e-017fc614339c-313"
        data-name="Path 414"
        d="M870.64978,499.466,765.85217,558.24911a2.73088,2.73088,0,0,1-3.63846-.813,2.63062,2.63062,0,0,1,.93491-3.70264L869.17885,494.259c3.43768.73179,3.47319,4.08393,1.47093,5.207Z"
        transform="translate(-247.81887 -132.5)"
        fill="#ccc"
      />
      <path
        id="b16b06d0-f954-457b-b4c1-332719c9f6d6-314"
        data-name="Path 415"
        d="M877.40779,511.51413,772.61018,570.2972a2.73088,2.73088,0,0,1-3.63846-.813,2.63062,2.63062,0,0,1,.93491-3.70264l106.03023-59.47447c3.43768.7318,3.47319,4.08393,1.47093,5.207Z"
        transform="translate(-247.81887 -132.5)"
        fill="#ccc"
      />
      <path
        id="b3c0d144-cc2c-4ac0-a007-a1ff15c76ba0-315"
        data-name="Path 416"
        d="M884.16139,523.55434,779.36377,582.33741a2.73086,2.73086,0,0,1-3.63845-.813,2.63061,2.63061,0,0,1,.9349-3.70264L882.69045,518.3473c3.43768.73179,3.47319,4.08393,1.47094,5.207Z"
        transform="translate(-247.81887 -132.5)"
        fill="#ccc"
      />
      <path
        id="b05e8afe-5132-428a-bb53-4b9505cb52a3-316"
        data-name="Path 417"
        d="M890.91889,535.60153,786.12128,594.38459a2.73087,2.73087,0,0,1-3.63846-.813,2.63062,2.63062,0,0,1,.93491-3.70264L889.448,530.39449c3.43768.73179,3.47319,4.08393,1.47093,5.207Z"
        transform="translate(-247.81887 -132.5)"
        fill="#ccc"
      />
      <path
        id="a26c59cb-bc72-4a1e-9571-41d1cd64f8ea-317"
        data-name="Path 418"
        d="M897.673,547.64264,792.87538,606.42571a2.73088,2.73088,0,0,1-3.63846-.813,2.63061,2.63061,0,0,1,.93491-3.70264L896.20206,542.4356c3.43768.7318,3.47319,4.08393,1.47093,5.207Z"
        transform="translate(-247.81887 -132.5)"
        fill="#ccc"
      />
      <path
        d="M879.39,592.76576a9.45375,9.45375,0,0,0-6.28012-13.06523l-2.85-33.47437-13.70641,10.81273,4.80775,30.13013A9.505,9.505,0,0,0,879.39,592.76576Z"
        transform="translate(-247.81887 -132.5)"
        fill="#ffb8b8"
      />
      <path
        d="M870.51888,576.19465l-11.38318,1.35761a4.23111,4.23111,0,0,1-4.72743-4.01809l-1.66009-38.40229L831.17086,476.186a12.14829,12.14829,0,0,1,.6413-11.56749,15.87769,15.87769,0,0,1,1.808-2.33307,12.10943,12.10943,0,0,1,10.56216-3.75039,12.62641,12.62641,0,0,1,9.48322,6.58957l14.07179,29.48684a83.765,83.765,0,0,1,8.09422,39.573l-1.58684,37.98781a4.228,4.228,0,0,1-3.66983,4.015Z"
        transform="translate(-247.81887 -132.5)"
        fill="#4f46e5"
      />
      <polygon
        points="663.126 609.664 652.572 614.296 629.682 575.793 645.258 568.956 663.126 609.664"
        fill="#ffb8b8"
      />
      <path
        d="M918.12738,751.21282l-34.02992,14.93724-.18895-.43042a14.46494,14.46494,0,0,1,7.43084-19.05957l.00084-.00037,20.78427-9.12305Z"
        transform="translate(-247.81887 -132.5)"
        fill="#2f2e41"
      />
      <polygon
        points="521.437 622.477 509.911 622.477 504.428 578.02 521.439 578.021 521.437 622.477"
        fill="#ffb8b8"
      />
      <path
        d="M772.19485,766.15005l-37.16391-.00137v-.47007a14.46491,14.46491,0,0,1,14.46532-14.46509h.00092l22.69836.00092Z"
        transform="translate(-247.81887 -132.5)"
        fill="#2f2e41"
      />
      <path
        d="M881.47116,739.77912,817.26467,596.49618a1.41043,1.41043,0,0,0-2.65605.18821L775.488,735.07846a4.20608,4.20608,0,0,1-4.5035,3.10042L749.619,736.04246a4.255,4.255,0,0,1-3.79817-4.55193c6.79576-84.52376,17.50859-164.29728,49.60162-216.5196l.17076-.27818,48.54351,9.51883L908.945,730.58529a4.24861,4.24861,0,0,1-2.75131,5.26437l-19.54309,6.28163a4.21518,4.21518,0,0,1-5.17945-2.35217Z"
        transform="translate(-247.81887 -132.5)"
        fill="#2f2e41"
      />
      <circle cx="571.23719" cy="285.9833" r="23.09061" fill="#ffb8b8" />
      <path
        d="M792.43885,518.96833l-.29448-.0606-.06932-.292c-3.1112-13.13523-3.18534-27.47041-.22057-42.60525a33.4679,33.4679,0,0,1,33.50759-27.07471h0a33.52278,33.52278,0,0,1,32.81832,32.23075c.98994,26.03174-9.64852,48.60042-9.75594,48.82536l-.15837.33051Z"
        transform="translate(-247.81887 -132.5)"
        fill="#4f46e5"
      />
      <path
        d="M730.53923,562.89708a9.45373,9.45373,0,0,0,7.58167-12.3555l26.596-20.52578L748.2039,524.35l-22.72441,20.36026a9.505,9.505,0,0,0,5.05974,18.18679Z"
        transform="translate(-247.81887 -132.5)"
        fill="#ffb8b8"
      />
      <path
        d="M745.06581,547.41726a4.21474,4.21474,0,0,1-5.40137-.95265l-7.30621-8.83375a4.23021,4.23021,0,0,1,.81749-6.15023l31.38286-22.19392,37.86553-50.06485a12.14733,12.14733,0,0,1,10.072-5.72555,15.84585,15.84585,0,0,1,2.93935.2562h0a12.10562,12.10562,0,0,1,8.87346,6.84813,12.62539,12.62539,0,0,1-.40335,11.54009l-28.03782,45.40675-.06149.09931Z"
        transform="translate(-247.81887 -132.5)"
        fill="#4f46e5"
      />
      <path
        d="M823.18538,417.05053c-1.70186-2.79949-5.45745-3.59039-8.71805-3.271s-6.43146,1.46543-9.70757,1.44c-9.09685-.07051-16.11871-11.15768-12.2941-19.41177,3.13659-6.76923,10.97027-9.74163,18.05675-12.07454,14.858-4.89134,31.92534-9.59107,45.40812-1.66014a28.21373,28.21373,0,0,1,13.58,25.14008c-.30324,6.24171-2.69368,12.65309-.672,18.5661,2.3323,6.82153,9.72724,10.48147,16.71568,12.25192,12.25051,3.10354,25.167,2.60362,37.4941,5.38725s24.95911,10.17486,28.282,22.36771c2.18257,8.00875-.0949,16.63035-3.86651,24.02485a62.22308,62.22308,0,0,1-86.80506,25.46124c-14.90268-8.70194-25.6618-23.409-32.0763-39.4298a132.02955,132.02955,0,0,1-7.63152-28.99961c-1.43659-9.29077-.21763-18.67976,2.38443-27.71368A2.54669,2.54669,0,0,0,823.18538,417.05053Z"
        transform="translate(-247.81887 -132.5)"
        fill="#2f2e41"
      />
      <path
        d="M906.48671,767.5h-190a1,1,0,0,1,0-2h190a1,1,0,1,1,0,2Z"
        transform="translate(-247.81887 -132.5)"
        fill="#ccc"
      />
    </svg>
  );
};

export default TaskIcon;
