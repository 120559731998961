import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import React, { useContext, useState } from "react";
import { ItineraryContext } from "./context/provider";

const EditTaskModal = ({ id, taskId, isOpen, setIsOpen, onClose }) => {
  const { editItineraryTask, getTaskById, deleteItineraryTask } =
    useContext(ItineraryContext);
  const { name, description } = getTaskById(id, taskId);
  const [taskName, setTaskName] = useState(name);
  const [taskDesc, setTaskDesc] = useState(description);

  const handleEdit = () => {
    if (!taskName || !taskDesc) {
      return;
    }

    // Add itinerary to the list
    editItineraryTask(id, taskId, {
      id: taskId,
      name: taskName,
      description: taskDesc,
    });

    setTaskName("");
    setTaskDesc("");
    setIsOpen(false);
    onClose();
  };

  const handleDelete = () => {
    deleteItineraryTask(id, taskId);
    setIsOpen(false);
    onClose();
  };

  return (
    <Transition show={isOpen}>
      <Dialog className="relative z-10" onClose={setIsOpen}>
        <TransitionChild
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/30 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-stretch sm:justify-end sm:p-0">
            <TransitionChild
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:translate-x-4"
              enterTo="opacity-100 translate-y-0 sm:translate-x-0"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:translate-x-0"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:translate-x-4"
            >
              <DialogPanel className="relative flex w-full transform flex-col overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:max-w-lg sm:rounded-none">
                <div className="flex-1 bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="w-full sm:flex sm:items-start">
                    <div className="mt-3 w-full sm:mt-0">
                      <DialogTitle
                        as="h3"
                        className="text-center text-base font-semibold leading-6 text-gray-900 sm:text-left"
                      >
                        Edit Task
                      </DialogTitle>
                      <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2">
                        <div className="col-span-1 sm:col-span-2">
                          <label
                            htmlFor="task-name"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Name your task
                          </label>
                          <div className="mt-2">
                            <input
                              type="text"
                              id="task-name"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              value={taskName}
                              onChange={(e) => setTaskName(e.target.value)}
                              placeholder="Name your task"
                            />
                          </div>
                        </div>
                        <div className="col-span-1 sm:col-span-2">
                          <label
                            htmlFor="task-desc"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Describe your task
                          </label>
                          <div className="mt-2">
                            <textarea
                              id="task-desc"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              value={taskDesc}
                              onChange={(e) => setTaskDesc(e.target.value)}
                              placeholder="Describe your task"
                              rows={4}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:justify-between sm:px-6">
                  <div className="sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                      onClick={handleEdit}
                    >
                      Edit Task
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => {
                        setIsOpen(false);
                        onClose();
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-red-300 hover:bg-red-600 sm:mt-0 sm:w-auto"
                    onClick={handleDelete}
                  >
                    Delete Task
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default EditTaskModal;
