import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import React, { useContext, useState } from "react";
import { ItineraryContext } from "./context/provider";

const AddTaskModal = ({ id, isOpen, setIsOpen }) => {
  const { addItineraryTask } = useContext(ItineraryContext);
  const [taskName, setTaskName] = useState("");
  const [taskDesc, setTaskDesc] = useState("");

  const handleAdd = () => {
    if (!taskName || !taskDesc) {
      return;
    }

    // Add itinerary to the list
    addItineraryTask(id, {
      id: Math.random().toString(36).substr(2, 9),
      name: taskName,
      description: taskDesc,
    });
    setTaskName("");
    setTaskDesc("");
    setIsOpen(false);
  };

  return (
    <Transition show={isOpen}>
      <Dialog className="relative z-10" onClose={setIsOpen}>
        <TransitionChild
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/30 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:justify-end sm:items-stretch sm:p-0">
            <TransitionChild
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:translate-x-4"
              enterTo="opacity-100 translate-y-0 sm:translate-x-0"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:translate-x-0"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:translate-x-4"
            >
              <DialogPanel className="relative flex flex-col transform overflow-hidden rounded-lg sm:rounded-none bg-white text-left shadow-xl transition-all w-full sm:max-w-lg">
                <div className="bg-white flex-1 px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start w-full">
                    <div className="mt-3 sm:mt-0 w-full">
                      <DialogTitle
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900 text-center sm:text-left"
                      >
                        Add New Task
                      </DialogTitle>
                      <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2">
                        <div className="col-span-1 sm:col-span-2">
                          <label
                            htmlFor="task-name"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Name your task
                          </label>
                          <div className="mt-2">
                            <input
                              type="text"
                              id="task-name"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              value={taskName}
                              onChange={(e) => setTaskName(e.target.value)}
                              placeholder="Name your task"
                            />
                          </div>
                        </div>
                        <div className="col-span-1 sm:col-span-2">
                          <label
                            htmlFor="task-desc"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Describe your task
                          </label>
                          <div className="mt-2">
                            <textarea
                              id="task-desc"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              value={taskDesc}
                              onChange={(e) => setTaskDesc(e.target.value)}
                              placeholder="Describe your task"
                              rows={4}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                    onClick={handleAdd}
                  >
                    Add Task
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setIsOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AddTaskModal;
